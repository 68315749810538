// eslint-disable-next-line import/no-unresolved -- https://vitejs.dev/config/build-options#build-modulepreload
import 'vite/modulepreload-polyfill';

import './config';
import initSecurityTokens from './security/init-tokens';
import './tooltip';
import './header';
import '../sass/main.scss';

// Reload the page on dynamic import errors, which may happen after deploying a new build.
// @see https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

initSecurityTokens();

// Load entry points asynchronously
void (async () => {
  if (document.querySelector('.admin-page') !== null) {
    // @ts-ignore TODO: TypeScript
    await import('./admin');
  }

  if (document.querySelector('#react-container') !== null) {
    await import('./application/app');
  } else {
    // Loading sidebar-app with react container causes conflicts
    await import('./application/sidebar-app');
  }
})();

if (import.meta.env.PROD) {
  const recruitingAd = 'Onko koodaaminen sinun alaasi? https://huutokaupat.com/meille-toihin';

  console.info(recruitingAd);
}
