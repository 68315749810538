import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTokens } from '@mezzoforte/forge';
import { PrismicSettingsContext } from '../prismic/prismic-settings-context';
import { Container } from './Container';
import { PrismicRichText } from '@prismicio/react';
import richTextComponents from '../prismic/rich-text-components';
import { RichTextField } from '@prismicio/client';

export interface HeaderTextBannersProps {
  maxNumItems?: number;
}

interface StyleProps {
  displayDuration: number;
  numItems: number;
}

const { colors, breakpoints, fonts, space } = useTokens.huutokaupat;

export const HeaderTextBanners: React.FC<HeaderTextBannersProps> = ({ maxNumItems = 3 }) => {
  const prismicSettings = useContext(PrismicSettingsContext);
  const bannerTexts = (prismicSettings?.data.header_info_texts ?? []).slice(0, maxNumItems);
  const displayDuration = prismicSettings?.data.header_info_text_animation_duration ?? 5000;

  if (bannerTexts.length === 0) return null;

  return (
    <BannerWrapper displayDuration={displayDuration} numItems={bannerTexts.length}>
      <Container textAlign="center" fontSize={14} fontWeight={500} fontFamily={fonts.heading}>
        {bannerTexts?.map((text: Record<string, RichTextField>, index: number) => (
          <PrismicRichText
            field={text.content}
            key={JSON.stringify(text.content)}
            components={{
              paragraph: props => (
                <BannerItem index={index} animate={bannerTexts.length > 1}>
                  {props.children}
                </BannerItem>
              ),
              ...richTextComponents,
            }}
          />
        ))}
      </Container>
    </BannerWrapper>
  );
};

const BannerItem = styled.span<{ index: number; animate: boolean }>`
  ${({ index, animate }) =>
    animate &&
    `
        grid-area: active;
        opacity: 0;
        animation: toggleDisplayed var(--display-duration) infinite,
          keepHidden calc(var(--display-duration) * var(--num-items)) infinite;
        animation-delay: calc(var(--display-duration) * ${index});
      `}

  @media screen and (min-width: ${breakpoints.lg}) {
    :not(:first-of-type):before {
      content: ' | ';
      color: ${colors.subdued};
      padding: 0 8px;
    }
  }
`;

const BannerWrapper = styled.aside<StyleProps>`
  --display-duration: ${props => props.displayDuration}ms;
  --num-items: ${props => props.numItems};
  // hex from brandSecondary with opacity .96
  background-color: #20371c;
  color: ${colors.textInvert};
  padding: ${space[2]} 0;

  a {
    color: inherit;
    text-decoration: underline;
  }

  div {
    display: grid;
    grid-template-areas: 'active';
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    span {
      opacity: 1;
      animation: none;
    }

    div {
      display: block;
    }
  }

  @keyframes toggleDisplayed {
    0%,
    100% {
      opacity: 0;
    }
    5%,
    95% {
      opacity: 1;
    }
  }

  @keyframes keepHidden {
    0% {
      visibility: visible;
    }
    ${({ numItems }) => 100 / numItems}%,
    100% {
      visibility: hidden;
    }
  }
`;
