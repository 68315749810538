import { compose } from 'redux';
import jwt, { AuthUser } from '../security/jwt';
import config from '../../../config';
import createClient from '../api/client';

const freshChatScripts = {
  main: 'https://wchat.eu.freshchat.com/js/widget.js',
  extra: '/js/freshchat.extra.script.js',
};

const vehicleTagPages = [
  'ajoneuvot-ja-tarvikkeet',
  'henkiloautot',
  'pakettiautot',
  'kevytkuorma-autot',
  'veneet',
  'moottoripyorat',
  'asuntovaunut-perakarryt',
  'moottorikelkat-ja-monkijat',
  'muut-ajoneuvot',
  'ajoneuvovaraosat',
  'ajoneuvotarvikkeet',
];

const sellerTagPages = ['yritystili', 'ilmoittaminen', 'ilmoittajaksi', 'mukaan-liittyminen'];

export const shouldDisplayWidget = (user: AuthUser | null) => {
  return !user || !user.isAdmin;
};

export const getFaqTags = ({ pathname }: Location, user: AuthUser | null): FaqTagsConfig => {
  const vehicleRegex = new RegExp(`^/(${vehicleTagPages.join('|')})$`);

  const shouldAddVehicleTag = vehicleRegex.test(pathname);
  const shouldAddKuhuTag = /^\/kuluttajat-autohuutokauppa$/.test(pathname);
  const shouldAddKuhu2Tag = /^\/kuluttajahuutokaupat$/.test(pathname);
  const shouldAddApartmentTag = /^\/asunnot$/.test(pathname);
  const shouldAddBecomeSellerTag = !user && /^\/myy-huutokaupatcomissa$/.test(pathname);

  const tags = [
    user?.isSeller ? 'ilmoittaminen' : '',
    user?.isBuyer ? 'ostaja' : '',
    shouldAddVehicleTag ? 'ajoneuvot' : '',
    shouldAddKuhuTag || shouldAddKuhu2Tag ? 'kuluttajahuutokaupat' : '',
    shouldAddApartmentTag ? 'asunnon myynti' : '',
    shouldAddBecomeSellerTag ? 'ilmoittajaksi' : '',
  ].filter(Boolean);

  if (!tags.length) {
    return {};
  }

  return {
    tags,
    filterType: 'article',
  };
};

export const getTags = ({ pathname }: Location) => {
  const sellerRegex = new RegExp(`^/(${sellerTagPages.join('|')})$`);
  const vehicleRegex = new RegExp(`^/(${vehicleTagPages.join('|')})$`);

  const shouldAddSellerTag = sellerRegex.test(pathname);
  const shouldAddVehicleTag = vehicleRegex.test(pathname);

  return ['kalevi-botti', shouldAddSellerTag ? 'ilmoittajaksi' : '', shouldAddVehicleTag ? 'ajoneuvot' : ''].filter(
    Boolean
  );
};

export const getConfig = (baseConfig: FreshchatWidgetConfig, location: Location, user: AuthUser | null) =>
  compose<FreshchatWidgetConfig, FreshchatWidgetConfig, FreshchatWidgetConfig>(
    freshchatConfig => ({
      ...freshchatConfig,
      faqTags: getFaqTags(location, user),
      tags: getTags(location),
    }),
    freshchatConfig => {
      if (!user) {
        return freshchatConfig;
      }

      return {
        ...freshchatConfig,
        externalId: user.uuid,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phoneNumber,
      };
    }
  )(baseConfig);

export function getWidget(): FreshchatWidget | null {
  const { fcWidget } = window;
  if (!fcWidget) {
    return null;
  }
  return fcWidget;
}

async function setUserProperties(widget: FreshchatWidget, properties: UserProperties) {
  const res = await widget.user.setProperties(properties);
  if (res.status !== 200) {
    throw new Error(`Failed to set user properties: ${res.status}`);
  }
}

export async function updateSessionIdentifier(widget: FreshchatWidget) {
  const response = await createClient().get('/chat/open');
  void setUserProperties(widget, { identifier: response.data.identifier });
}

export function updateLoggedInState(widget: FreshchatWidget, state: boolean) {
  void setUserProperties(widget, { loggedIn: state });
}

function initializeChatProperties(widget: FreshchatWidget, user: AuthUser | null) {
  updateLoggedInState(widget, !!user);
  if (user !== null && widget.isOpen()) {
    void updateSessionIdentifier(widget);
  }
}

export const manageFreshchat = (): void => {
  const { location } = window;

  const widget = getWidget();
  if (!widget) {
    return;
  }

  const user = jwt.getUser();

  const shouldWidgetBeVisible = shouldDisplayWidget(user);
  const isWidgetVisible = widget.isInitialized();

  widget.on('widget:opened', () => {
    const jwtUser = jwt.getUser(); // user might be outdated at this point, se we need to get it again
    updateLoggedInState(widget, jwtUser !== null);

    if (jwtUser !== null) {
      void updateSessionIdentifier(widget);
    }
  });

  switch (true) {
    case shouldWidgetBeVisible && !isWidgetVisible:
      widget.init(getConfig(config.freshchat, location, user));
      initializeChatProperties(widget, user);
      break;

    case !shouldWidgetBeVisible && isWidgetVisible:
      widget.destroy();
      break;

    case shouldWidgetBeVisible && isWidgetVisible:
      widget.setTags(getTags(location));
      widget.setFaqTags(getFaqTags(location, user));
      break;

    default:
      break;
  }
};

const isScriptAlreadyLoaded = (fileName: string) => {
  // Get all scripts
  const len = Array.from(document.querySelectorAll('script')).filter(elem => {
    const src = elem.getAttribute('src') ?? '';
    return src.includes(fileName);
  }).length;

  if (len === 0) {
    // Script not found
    return false;
  }
  return true;
};

export const addFreshchatScript = (): void => {
  if (!config.isFreshchatEnabled || isScriptAlreadyLoaded('freshchat.extra.script.js')) {
    return;
  }

  const scriptTag = document.createElement('script');

  // eslint-disable-next-line functional/immutable-data -- This is the way.
  scriptTag.async = true;
  // eslint-disable-next-line functional/immutable-data -- This is the way.
  scriptTag.src = freshChatScripts.main;
  // eslint-disable-next-line functional/immutable-data -- This is the way.
  scriptTag.onload = manageFreshchat;

  document.head.appendChild(scriptTag);

  // Extra script for handling custom chat-icon/button
  const extraScriptTag = document.createElement('script');

  // eslint-disable-next-line functional/immutable-data -- This is the way.
  extraScriptTag.async = true;
  // eslint-disable-next-line functional/immutable-data -- This is the way.
  extraScriptTag.src = freshChatScripts.extra;

  document.head.appendChild(extraScriptTag);
};

export const removeFreshchatScript = (): void => {
  // Get all scripts
  document.querySelectorAll('script').forEach(elem => {
    const src = elem.getAttribute('src') ?? '';
    if (src.includes(freshChatScripts.main) || src.includes(freshChatScripts.extra)) {
      document.head.removeChild(elem);
    }
  });
};
